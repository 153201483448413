(function () {
  var indexPictograms: any = $("#index-pictograms");

  if (indexPictograms) {
    indexPictograms.owlCarousel({
      scrollPerPage: true,
      itemsCustom: [[0, 3]]
    });
  }
})();
